import { policyList } from "@/enums/permissions";
import { LandingItem } from "@/interfaces/landingItem";
import { BooleanCheck } from "@/types/types";
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./MoneyMenu.template.vue";

@Component({
  mixins: [Template],
  components: {
    landing: () =>
      import("@/components/sharedComponents/landing/landing.component")
  }
})
export default class MoneyMenuComponent extends Vue {
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  public landingOptions: LandingItem[] = [];
  public created() {
    this.landingOptions = [
      {
        namedRoute: "money-manager",
        translationString: "money_manager.title"
      },
      {
        namedRoute: "close-till",
        translationString: "close_till",
        visibleCondition: () => {
          return this.hasPermission(policyList.employeeCloseTill);
        }
      },
      {
        namedRoute: "payout-till",
        translationString: "payout_from_till",
        visibleCondition: () => {
          return this.hasPermission(policyList.employeePayoutFromTill);
        }
      }
    ];
  }
}
